.platformWelcome .summary{
    width: 100%;
    height: 100%;
}

.platformWelcome .summary .defaulttitle{
    color: rgba(0, 0, 0, 0.45);
    font-size: 17px;
    text-align: center;
    width: 100%;
    height: 20px;
    /* float: left; */
    /* margin-bottom: 15px; */
}
.platformWelcome .summary .defaulttitleName{
 float: left;
}

.platformWelcome .summary .defaulttitleCount{
 float: left;
 color: rgba(0, 0, 0, 0.85);
}

.platformWelcome .summary .totalMealAmount{
    color: rgba(0, 0, 0, 0.45);
    font-size: 17px;
    width: 100%;
    /* margin-bottom: 10px; */
    /* text-align: center; */
    float: left;
    margin-top: 2rem;
    
}

.platformWelcome .summary .totalMealAmount .totalMealAmountLeft .amountImg .amountImg1{
    width: 100%;
    height: 100%;
    
}

.platformWelcome .summary .totalMealAmount .totalMealAmountLeft .amountImg{
    width: 3rem;
    height: 3rem;
    text-align: center;
    margin: 6px auto;
    
}

.platformWelcome .summary .totalMealAmount .totalMealAmountLeft{
    width: 15%;
    height: 100%;
    /* background-color: aqua; */
    text-align: center;
    float: left;
    /*  */
}
.platformWelcome .summary .totalMealAmount .totalMealAmountLeft .defaulttitleName1{
    font-size: 14px;
}
.platformWelcome .summary .totalMealAmount .totalMealAmountLeft .defaulttitleCount1{
    font-size: 24px;
    font-weight: 600;
}

.platformWelcome .margintop10{
    margin-top: 10px;
}