.participateStatistics {
    overflow: auto;
    padding: 1rem;
    direction: rtl;
}

.participateStatistics .zhRoot {
    direction: ltr;
}

.participateStatistics .participateStatisticsSelect {
    width: 86px;
    margin: 0 2px;
}

.participateStatistics .participateStatisticsDatePicker {
    width: 120px;
}

.participateStatistics .amountAll {
    margin-top: -50px;
    height: 100px;
    width: 50%;
}

.participateStatistics .amount {
    font-weight: 700;
}