.register .registerPwdRoot {
    text-align: center;
}

.registerFormInputWidth{
    max-width: 300px;
    margin:0 auto!important;
}

.registerPhoneInput{
    width: 100%;
}

.registerPasswordInput{
    width: 100%;
}

.registerVerificationCodeInput{
    width: 65%;
}

.registerVerification{
    width: 34%;
    left: 1%;
}

.registerButton{
    width: 100%;
}