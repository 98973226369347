.districtNewUserStatistics {
    overflow: auto;
    padding: 1rem;
    direction: rtl;
}

.districtNewUserStatistics .zhRoot {
    direction: ltr;
}

.content_center {
    text-align: center;
}

.districtNewUserStatistics .districtNewUserStatisticsSelect {
    width: 86px;
    margin: 0 2px;
}

.districtNewUserStatistics .districtNewUserStatisticsDatePicker {
    width: 120px;
}
.districtNewUserStatistics .amount{
    font-weight: 700;
}