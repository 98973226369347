

.promotionPerformanceSettlement  .space{
    height: 10px;
    width: 10px;
}

.promotionPerformanceSettlement .datePicker1 {
    width: 115px;
} 

.promotionPerformanceSettlement .weight {
    font-weight: 700;
}