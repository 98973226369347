/* .sysFunc {
    width: 80vw;
    height: 30vh;
} */

.myList .cashier .cashierRight .paymentMethod{
    margin-left: 10%;
    font-size: 17px;
    font-family: fantasy;
    font-weight: bold;
    float: left;
}

.cashier .cashierRight .cashierPitrue{
    margin-left: 10%;
    font-size: 17px;
    float: left;

}

.cashier .cashierRight .cashierPitrue .img{
    width: 90px;
    height: 90px;
    float: left;
    margin-top: 15px;
}

.cashier .cashierRight .radio{
    margin-left: 10%;
    float: left;
}

.cashier .ant-radio{
    box-sizing: border-box;
    margin: 10px 0 0 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: sub;
    outline: none;
    cursor: pointer;
}
