

.shopToPay .payWeiXin{
    width: 180px;
    height: 32px;
    line-height: 32px;
}

.shopToPay .payWeiXinRadio{
    float: left;
    width: 20px;
    height: 32px;
    line-height: 32px;
}

.shopToPay .payWeiXinSelect{
    float: left;
    width: 130px;
    height: 32px;
    line-height: 32px;
    border: 1px solid rgb(24 144 255);
}

.shopToPay .payWeiXinImg{
    width: 110px;
    height: 30px;
    /* margin-right: 10px; */
    float: left;
}
.shopToPay .payWeiXinFont{
    width: 80px;
    height: 32px;
    font-size: 16px;
    color: #666;
    float: left;
}
.shopToPay .payWeiXinFont:hover{
    color: #e31613;
}

.shopToPay .toPayRow{
    margin-top: 20px;
}

.shopToPay .tabButton{
    width: 85px;
    height: 32px;
    float: left;
}


.modalTitle{
    width: 100%;
    text-align: center;
    font-size: 16px;
}

.shopToPayModal .ant-modal-body{
    text-align: center;
    height: 550px;
    padding: 20px;
}

.shopToPayModal .topInfo{
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    margin: 0 0 5px 0;
    color: #ccc;
}
.shopToPayModal .marginBttom{
    margin: 0 0 10px 0;
}

.shopToPayModal .topInfo .order{
    width: 50%;
    float: left;
    text-align: left;
}
.shopToPayModal .topInfo .amount{
    width: 50%;
    float: left;
    text-align: left;
}
.shopToPayModal .topInfo .amount .amountFont{
    font-size: 18px;
    color:#e31613;
}
.shopToPayModal .topInfo .infoValue{
    color: rgba(0, 0, 0, 0.85);
}

.shopToPayModal .textLeft{
    text-align: left;
}
.shopToPayModal .textLeft .redFont{
    color: #e31613;
}

.shopToPayModal .leftImg{
    width: 50%;
    height: 400px;
    float: left;
}
.shopToPayModal .leftImg .payQrCode{
    width: 329px;
    height: 329px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}
.shopToPayModal .leftImg .tishi{
    width: 329px;
    height: 60px;
}
.shopToPayModal .rightInfo{
    width: 50%;
    height: 400px;
    float: left;
}
.shopToPayModal .rightInfo .phoneBtn{
    width: 300px;
    height: 400px;
}


