.newUserStatistics {
    overflow: auto;
    padding: 1rem;
    direction: rtl;
}

.newUserStatistics .zhRoot {
    direction: ltr;
}

.content_center {
    text-align: center;
}

.newUserStatistics .newUserStatisticsSelect {
    width: 86px;
    margin: 0 2px;
}

.newUserStatistics .newUserStatisticsDatePicker {
    width: 120px;
}
.newUserStatistics .amount{
    font-weight: 700;
}