.withdrawalBankCard .disableFontColor {
    color: rgba(0, 0, 0, 0.65)!important;
}

.withdrawalBankCard .RemarkInput {
    height: auto;
    min-height: 34px;
    margin-top: 5px;
    margin-right: 30px;
    width: 68.5%;
  }

  .withdrawalBankCard .picture{
    width: 100px;
    height: 100px;
  }