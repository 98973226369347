.pictureColumns{
    width: 177px;
    height: 75px;
}
.homepageScrollConfigUrl .ant-upload-list-picture-card .ant-upload-list-item {
    width: 355px! important;
    height: 150px! important;
}
.homepageScrollConfigUrl .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: fill! important
}