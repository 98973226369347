

.marketingPromotionStatistics  .space{
    height: 10px;
    width: 10px;
}

.marketingPromotionStatistics .newUserStatisticsDatePicker {
    width: 120px;
}
.marketingPromotionStatistics .newUserStatisticsDatePicker1 {
    width: 155px;
}

.marketingPromotionStatistics .weight {
    font-weight: 700;
}