

.promotionDetails  .space{
    height: 10px;
    width: 10px;
}

.promotionDetails .Picker1 {
    width: 115px;
}
.promotionDetails .weight {
    font-weight: 700;
}