

.batchRebateManagement .payWeiXin{
    width: 180px;
    height: 32px;
    line-height: 32px;
}

.batchRebateManagement .payWeiXinRadio{
    float: left;
    width: 20px;
    height: 32px;
    line-height: 32px;
}

.batchRebateManagement .payWeiXinSelect{
    float: left;
    width: 130px;
    height: 32px;
    line-height: 32px;
    border: 1px solid rgb(24 144 255);
}

.batchRebateManagement .payWeiXinImg{
    width: 110px;
    height: 30px;
    /* margin-right: 10px; */
    float: left;
}
.batchRebateManagement .payWeiXinFont{
    width: 80px;
    height: 32px;
    font-size: 16px;
    color: #666;
    float: left;
}
.batchRebateManagement .payWeiXinFont:hover{
    color: #e31613;
}

.batchRebateManagement .toPayRow{
    margin-top: 20px;
}


.modalTitle{
    width: 100%;
    text-align: center;
    font-size: 16px;
}

.batchRebateManagementModal .ant-modal-body{
    text-align: center;
    height: 550px;
    padding: 20px;
}

.batchRebateManagementModal .topInfo{
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    margin: 0 0 5px 0;
    color: #ccc;
}
.batchRebateManagementModal .marginBttom{
    margin: 0 0 10px 0;
}

.batchRebateManagementModal .topInfo .order{
    width: 50%;
    float: left;
    text-align: left;
}
.batchRebateManagementModal .topInfo .amount{
    width: 50%;
    float: left;
    text-align: left;
}
.batchRebateManagementModal .topInfo .amount .amountFont{
    font-size: 18px;
    color:#e31613;
}
.batchRebateManagementModal .topInfo .infoValue{
    color: rgba(0, 0, 0, 0.85);
}

.batchRebateManagementModal .textLeft{
    text-align: left;
}
.batchRebateManagementModal .textLeft .redFont{
    color: #e31613;
}

.batchRebateManagementModal .leftImg{
    width: 50%;
    height: 400px;
    float: left;
}
.batchRebateManagementModal .leftImg .payQrCode{
    width: 329px;
    height: 329px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}
.batchRebateManagementModal .leftImg .tishi{
    width: 329px;
    height: 60px;
}
.batchRebateManagementModal .rightInfo{
    width: 50%;
    height: 400px;
    float: left;
}
.batchRebateManagementModal .rightInfo .phoneBtn{
    width: 300px;
    height: 400px;
}


