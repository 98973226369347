.zhRoot .textArea{
    width: 100%;
    /* height: 300px; */
}

.zhRoot .editorElemMenu{
    width: 100%;
    height:20%;
    background-color:green ;
}

.zIndexDate{
    position: inherit;
    z-index: 99;
}

.zIndexWEditor{
    position: inherit;
    z-index: 1;
}