
.shop .imgUpload .ant-upload-list-picture-card .ant-upload-list-item {
    width: 23.5rem! important;
    height: 7rem! important;
}
.shop .imgUpload .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
    object-fit: fill! important
}
.shop .imgUpload .shop .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: fill! important
}
#pickerInput {
    position: absolute;
    z-index: 1;
    width: 18rem;
}

#pickerBox {
    position: absolute;
    z-index: 1;
}
.w-e-text-container {
    position: relative;
    height: 600px!important;
}

.shopViewModal .ant-modal-body{
    padding: 10px;
}

.shopViewModal .ant-modal-close{
    margin-top: 10px;
    margin-right: 10px;
    background-color: rgba(128, 128, 128, 0.5);
    border-radius: 50%;
}

.shopViewModal .ant-modal-close-x{
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
}

.shopViewModal .testBody{
    position: relative;
    height: calc(100vh - 140px);
    width: 100%;
}

.shopViewModal .carouselBox {
    position: absolute;
    padding: 10px;
    bottom: 0px;
    margin: 0 auto;
    display: flex;
    overflow-x: auto;
    gap: 10px;
    width: 100%;
}

.shopViewModal .imgBox {
    min-width: 100px;
    max-width: 100px;
    height: 50px;
}

.shopViewModal .imgBox > img {
    width: 100%;
    height: 100%;
}

.shopViewModal .selectImgBox {
    box-shadow: 0 0 0 1px red, 0 1px 3px 1px red;
}