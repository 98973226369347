


.agencyManagement .imgUpload .ant-upload-list-picture-card .ant-upload-list-item {
    width: 23.5rem! important;
    height: 7rem! important;
}
.agencyManagement .imgUpload .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
    object-fit: fill! important
}
.agencyManagement .imgUpload .agencyManagement .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: fill! important
}

#pickerInput {
    position: absolute;
    z-index: 1;
    width: 18rem;
}

#pickerBox {
    position: absolute;
    z-index: 1;
}

.w-e-text-container {
    position: relative;
    height: 600px!important;
}