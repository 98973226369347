.userRebateStatistics .amount{
    font-weight: 700;
}
.userRebateStatistics {
    overflow: auto;
    padding: 1rem;
    direction: rtl;
}

.userRebateStatistics .zhRoot {
    direction: ltr;
}

.content_center {
    text-align: center;
}

.userRebateStatistics .userRebateStatisticsSelect {
    width: 86px;
    margin: 0 2px;
}

.userRebateStatistics .userRebateStatisticsDatePicker {
    width: 120px;
}
.userRebateStatistics .amount{
    font-weight: 700;
}