/**当前项目公共样式zh开头**/
iframe {
  position: unset!important;
}
.zhRoot {
  width: 80vw;
  height: auto;
  margin-top: 10px;
  margin-left: 30px;
}

.zhRoot .ant-table-pagination.ant-pagination{
  float: none;
  text-align: center;
  margin:15px 0px;
}
.zhRoot .ant-table-tbody{
  border-left: 1px dashed #cecece;
}
.zhRoot .ant-table-tbody > tr > td{
  padding: 8px 10px!important;
}
.zhRoot .ant-table-thead > tr > th{
  padding: 7px 10px!important;
}
.zhRoot .ant-tabs-bar{
  margin: 0 0 0px 0!important;
}
.zhRoot .ant-table-thead > tr > th{
  background-image: linear-gradient(0deg, 
  #e4e9f8 0%, 
  #e1e8fc 0%, 
  #dee6ff 0%, 
  #afbeeb 100%);
}

.zhTitle {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.zhLine {
  margin-bottom: 15px;
}

.zhTxt {
  height: auto;
  margin-top: 5px;
  min-height: 34px;
  color: #333;
  font-size: 14px;
  font-weight: 300;
}

.redStar {
  color: red;
}

.zhInput {
  height: auto;
  min-height: 34px;
  margin-top: 5px;
  margin-right: 30px;
}

.zhRowButtonRight {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: right;
}

.zhRowButtonLeft {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.zhInputNumber {
  width: 100%!important;
}

.zhSelect {
  width: 100%!important;
}

.zhCheckbox {
  margin-top: 5px;
  width: 100%!important;
}

.zhDatePicker {
  width: 100%!important;
}

.zhRow {
  margin-top: 20px;
}

.zhButtonLeft {
  margin-left: 5px;
}

.zhButtonRight {
  margin-left: 5px;
}

/** 必填样式 */

code {
  padding: 2px 4px;
  color: #c7254e;
  background-color: #fff;
}

/* 表格行背景颜色 */

/* 奇数行样式 */

.zhRoot tr:nth-child(odd) {
  background: #fff;
}

/* 偶数行样式 */

.zhRoot tr:nth-child(even) {
  background: #f3f6ff;
}

/*表格选中样式*/

.ant-table-tbody>tr.ant-table-row-selected td {
  background-color: #99CCFF!important;
}

.tableRightAlignment {
  text-align: right!important;
}

.tableCenterAlignment {
  text-align: center!important;
}

.tableWidth3 {
  width: 3%;
}

.tableWidth302 {
  width: 3.2%;
}

.tableWidth305 {
  width: 3.5%;
}

.tableWidth4 {
  width: 4%;
}

.tableWidth405 {
  width: 4.5%;
}

.tableWidth5 {
  width: 5%;
}

.tableWidth505 {
  width: 5.5%;
}

.tableWidth5088 {
  width: 6.1%;
}

.tableWidth6 {
  width: 6%;
}

.tableWidth601 {
  width: 6.1%;
}

.tableWidth605 {
  width: 6.5%;
}

.tableWidth7 {
  width: 7%;
}

.tableWidth705 {
  width: 7.5%;
}

.tableWidth8 {
  width: 8%;
}

.tableWidth8033 {
  width: 8.7%;
}

.tableWidth805 {
  width: 8.5%;
}

.tableWidth9 {
  width: 9%;
}

.tableWidth10 {
  width: 10%;
}

.tableWidth11 {
  width: 11%;
}

.tableWidth12 {
  width: 12%;
}

.tableWidth13 {
  width: 13%;
}

.tableWidth14 {
  width: 14%;
}

.tableWidth15 {
  width: 15%;
}

.tableWidth16 {
  width: 16%;
}

.tableWidth17 {
  width: 17%;
}

.tableWidth18 {
  width: 18%;
}

.tableWidth20 {
  width: 20%;
}

.tableWidth205 {
  width: 20.5%;
}

.tableWidth23 {
  width: 23%;
}

.tableWidth24 {
  width: 24%;
}

.tableWidth25 {
  width: 25%;
}

.tableWidth30 {
  width: 30%;
}

.tableWidth31 {
  width: 31%;
}

.tableWidth33 {
  width: 33%;
}

.tableWidth34 {
  width: 34%;
}

.tableWidth35 {
  width: 35%;
}

.tableWidth40 {
  width: 40%;
}

.tableWidth44 {
  width: 44%;
}

.tableWidth445 {
  width: 44.5%;
}

.tableWidth45 {
  width: 45%;
}

.tableWidth50 {
  width: 50%;
}

.tableWidth52 {
  width: 52%;
}

.tableWidth55 {
  width: 55%;
}

.tableWidth555 {
  width: 55.5%;
}

.tableWidth56 {
  width: 56%;
}

.tableWidth60 {
  width: 60%;
}

.tableWidth65 {
  width: 65%;
}

.tableWidth70 {
  width: 60%;
}

.tableWidth72 {
  width: 72%;
}

.pd_100percent {
  width: 100%;
}

.parts-numberInput {
  width: 100%!important;
}

/* 超出长度显示省略号 */

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

textarea:-ms-input-placeholder {
  color: #cecbcb!important;
  opacity: 1;
}

textarea::-webkit-input-placeholder {
  color: #cecbcb!important;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #cecbcb!important;
  opacity: 1;
}

input::-webkit-input-placeholder {
  color: #cecbcb!important;
  opacity: 1;
}


.tableStyle .ant-table-pagination.ant-pagination{
  float: none;
  text-align: center;
  margin:15px 0px;
}
.tableStyle .ant-table-tbody{
  border-left: 1px dashed #cecece;
}
.tableStyle .ant-table-tbody > tr > td{
  padding: 8px 10px!important;
}
.tableStyle .ant-table-thead > tr > th{
  padding: 7px 10px!important;
}
.tableStyle .ant-tabs-bar{
  margin: 0 0 0px 0!important;
}
.tableStyle .ant-table-thead > tr > th{
  background-image: linear-gradient(0deg, 
  #e4e9f8 0%, 
  #e1e8fc 0%, 
  #dee6ff 0%, 
  #afbeeb 100%);
}

/* 表格行背景颜色 */

/* 奇数行样式 */

.tableStyle tr:nth-child(odd) {
  background: #fff;
}

/* 偶数行样式 */

.tableStyle tr:nth-child(even) {
  background: #f3f6ff;
}
.ant-menu-root.ant-menu-inline{
  max-height: calc(100vh - 70px);
  overflow: overlay;
  overflow-x: hidden;
}