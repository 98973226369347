.forgetPwd .forgetPwdRoot {
    text-align: center;
}

.forgetPwdFormInputWidth{
    max-width: 300px;
    margin:0 auto!important;
}

.forgetPwdPhoneInput{
    width: 100%;
}

.forgetPwdPasswordInput{
    width: 100%;
}

.forgetPwdVerificationCodeInput{
    width: 65%;
}

.forgetPwdVerification{
    width: 34%;
    left: 1%;
}

.forgetPwdButton{
    width: 100%;
}