/* .cashier{
    background-color: #f5f5f5;
} */
.cashier .btnStylez{
    padding: 0 2px!important;
    height: 31px!important;
}
.cashier .cashierFrame {
    width: 80%;
    height: auto;
    border: 1px;
    border-radius: 10px;
    background-color: #dddddd;
    padding-top: 1px;
}

.cashier .cashierFrame .searchPhone {
    width: 37%;
    height: 32px;
    background-color: #FFFFFF!important;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-top: 12px;
    border-radius: 5px;
}

.cashier .cashierFrame .searchPhone .searchPhoneLeft {
    width: 10%;
    height: 100%;
    float: left;
}

.cashier .cashierFrame .searchPhone .searchPhoneLeft .img {
    width: 45%;
    height: 56%;
    margin-left: 30%;
    margin-top: 12%;
}

.cashier .cashierFrame .searchPhone .dottedLine {
    float: left;
    border: 1px;
    border-style: dashed;
    height: 100%;
}

.cashier .cashierFrame .searchPhone .searchPhoneMid {
    width: 75%;
    height: 100%;
    float: left;
    /* background-color: green!important; */
}

.cashier .cashierFrame .searchPhone .searchPhoneRight {
    width: 15%;
    height: 100%;
    float: left;
    background-color: #1890ff;
    color: #ffffff;
    text-align: center;
    line-height: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.cashier .cashierLeft {
    background-color: #FFFFFF!important;
    width: 27.5%!important;
    height: 30rem;
    float: left;
    border-radius: 10px;
    margin: 0.5% 0.5% 0.5% 0.5%;
}

.cashier .cashierLeft .row {
    width: 100%;
    /* height: 12%; */
    margin-top: 8px;
}

.cashier .cashierLeft .row .zhTxt1 {
    height: auto;
    margin-left: 30px;
    margin-top: 5px;
    min-height: 34px;
    font-family: fantasy;
    /* font-weight: bold; */
    font-size: 15px;
}
.cashier .cashierLeft .row .zhInput1 {
    height: auto;
    min-height: 34px;
    margin-top: 5px;
}

.cashier .cashierRight {
    width: 36.5%;
    height: 30rem;
    float: left;
    background-color: #FFF!important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.cashier .cashierMember .underline {
    width: 60%;
    display: inline-block;
    text-align: right;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
}

.cashier .cashierMember .underline .nickName {
    margin: 10px 20px 10px 0;
}


.cashier .cashierRight .cashierPaymentway {
    text-align: center;
    width: 25%;
    height: 100%;
    float: left;
}

.cashier .cashierFrame .cashierRight .paymentMethod {
    font-size: 17px;
    font-family: fantasy;
    font-weight: bold;
    float: left;
    width: 100%;
    height: 20%;
    border-bottom: 1px solid gray;
    border-top-left-radius: 10px;
}

.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodLeft {
    float: left;
    width: 33%;
    height: 100%;
    border-right: 1px solid #DDDDDD;
}
.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodTitle{
    /* color: rgba(0, 0, 0, 0.45); */
    text-align: center;
    line-height: 50px;
}
.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodCountAmount{
    line-height: 37px;
    font-size: 18px;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    color: rgba(0, 0, 0, 0.85);
}
.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodLeft .paymentMethodPayment {
    color: #000;
    text-align: center;
    line-height: 40px;
}

.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodLeft .paymentAmount {
    line-height: 37px;
    text-align: center;
}

.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodLeft .paymentAmount .number {
    width: 100%!important;
    font-size: 10px!important;
}

.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodMiddle {
    width: 33%;
    height: 100%;
    border-right: 1px solid #DDDDDD;
    float: left;
}

.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodMiddle .paymentMethodreceipts {
    /* color: rgba(0, 0, 0, 0.45); */
    text-align: center;
    line-height: 50px;
}

.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodMiddle .paymentAmount {
    line-height: 37px;
    text-align: center;
}

.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodMiddle .paymentAmount .number {
    width: 80%!important;
    color: #1890ff;
}

.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodRight {
    width: 33%;
    height: 100%;
    float: left;
}

.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodRight .paymentMethodGiveChange {
    color: #000;
    text-align: center;
    line-height: 40px;
}

.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodRight .paymentAmount {
    margin-left: 30%;
    line-height: 37px;
}

.cashier .cashierFrame .cashierRight .paymentMethod .paymentMethodRight .paymentAmount .number {
    font-size: 10px!important;
    font-weight: 100px!important;
    color: #FFAA33;
}

.cashier .cashierFrame .cashierRight .paymentMethod .img2 {
    width: 25%;
    height: 100%;
    font-size: 10pt;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #AAFFEE!important;
    float: left;
    padding: 10px;
}

.cashier .cashierFrame .cashierRight .paymentMethod .img1 {
    width: 25%;
    height: 100%;
    font-size: 10pt;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}

.cashier .cashierFrame .cashierRight .paymentMethod .line {
    border-right: 1px solid #DDDDDD;
}

.cashier .cashierFrame .cashierRight .paymentMethod .img1 .imgPicture {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    float: left;
}

.cashier .cashierFrame .cashierRight .paymentMethod .img2 .imgPicture {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    float: left;
}

.cashier .cashierRight .paymentMethod .img2 .words {
    width: 40px;
    height: 20px;
    float: left;
}

.cashier .cashierRight .cashierPaymentButton {
    text-align: center;
    width: 100%;
    height: 80%;
    float: left;
    background-color: #dddddd!important;
    /* border-bottom-left-radius: 10px; */
}

.cashier .cashierRight .cashierPaymentButton .img2 {
    width: 100%;
    height: 18%;
    font-size: 10pt;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #AAFFEE!important;
}

.cashier .cashierRight .cashierPaymentButton .rightPayment .img2 .words {
    width: 40px;
    height: 20px;
    float: left;
}

.cashier .cashierRight .cashierPaymentButton .rightPayment .line {
    border-bottom: 1px solid #DDDDDD;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber {
    /* width: 75%; */
    width: 100%;
    height: 100%;
    background-color: #F5F5F5!important;
    float: left;
    border-bottom-right-radius: 10px;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .leftMidule{
    width: 96%;
    height: 76%;
    margin: 2%;
    background-color: #fff;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .leftMidule .firstRow{
    width: 100%;
    height: 4.5rem;
    float: left;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .leftMidule .firstRow .number{
    float: left;
    width: 33.33%;
    height: auto;
    line-height: 4.5rem;
    font-size: 20px;
    border: 1px solid #DDDDDD;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .leftBottom{
    width: 96%;
    height: 20%;
    float: left;
    margin-left: 3%;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .leftBottom .button{
    float: left;
    width: 31%;
    height: 4.5rem;
    margin-right: 2%;
    line-height: 4.5rem;
    font-size: 20px;
    font-weight: bold;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .leftBottom .bottomCalr{
    background-color: #B0B0B0!important;
}

.cashier .bottomCalrbul{
    background-color: #AAFFEE!important;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .leftBottom .bottomCalrshen{
    background-color: #1890ff!important;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .leftBottom .button1{
    float: left;
    width: 31%;
    height: 4.5rem;
    line-height: 4.5rem;
    font-size: 20px;
    font-weight: bold;
}


.cashier .cashierRight .cashierPaymentButton .leftNumber .secondRow {
    width: 99%;
    height: 19%;
    float: left;
    margin-left: 8%;
    margin-top: 1%;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .secondRow .number {
    height: 80%;
    width: 20%;
    background-color: #FFFFFF!important;
    float: left;
    line-height: 78px;
    font-weight: bold;
    font-size: 20px;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .secondRow .number1 {
    height: 80%;
    width: 20%;
    background-color: #FFFFFF!important;
    float: left;
    line-height: 78px;
    font-weight: bold;
    font-size: 20px;
    margin-left: 12%;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .secondRow .button {
    height: 100%;
    width: 25%;
    background-color: #FFFFFF!important;
    float: left;
    line-height: 78px;
    font-weight: bold;
    font-size: 20px;
}

.cashier .cashierRight .cashierPaymentButton .secondRow {
    width: 100%;
    height: 15%;
    float: left;
    text-align: center;
    margin-top: 15px;
}

.cashier .cashierRight .cashierPaymentButton .secondRow .number {
    height: 100%;
    width: 25%;
    background-color: #FFFFFF!important;
    float: left;
    line-height: 78px;
    font-weight: bold;
    font-size: 20px;
}

.cashier .cashierRight .cashierPaymentButton .secondRow .number1 {
    height: 100%;
    width: 25%;
    background-color: #FFFFFF!important;
    float: left;
    line-height: 78px;
    font-weight: bold;
    font-size: 20px;
    margin-left: 12.5%;
}

.cashier .cashierRight .cashierButton {
    text-align: center;
    width: 80%;
    height: 100%;
    float: left;
}

.cashier .zhRoot .cashierFrame .cashierLeft .row .remark {
    margin-left: 7%;
    width: 90%!important;
}

.cashier .ant-statistic-title {
    margin-bottom: 22px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 17px;
    margin-top: 10px;
    text-align: center;
}

.cashier .ant-statistic-content {
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    text-align: center;
}

.cashier .classMunber {
    font-size: 15px;
    color: #1890ff;
}

.cashier .classMunber img{
    width: 40px;
    margin-right: 10px;
}

.cashier .site-statistic-demo-card {
    background: #ececec;
    padding: 30px;
    width: 100%;
    height: 100%;
}

.cashier .footAndwine {
    width: 100%;
    float: left;
}

.cashier .foot {
    float: left;
    height: auto;
    width: 45%;
}

.cashier .footRoght {
    margin-left: 10%;
}

.cashier .cashierName {
    width: 40%;
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
}

.cashier .cashierMember {
    width: 88%;
    float: left;
    margin: 0 10px 0 28px;
    letter-spacing: 1px;
}
.cashier .cashierMarginLeft{
    margin-left: 30px;
}

.cashier .ant-table {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    clear: both;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .threeRow {
    width: 99%;
    height: 19%;
    float: left;
    margin-left: 2%;
    margin-top: 1%;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .threeRow .button1 {
    background-color: #FFFFFF!important;
    float: left;
    line-height: 78px;
    font-weight: bold;
    font-size: 20px;
    margin-left: 9.5%;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .threeRow .list {
    background-color: #B0B0B0!important;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .threeRow .myList {
    background-color: #AAFFEE!important;
}

.cashier .cashierRight .cashierPaymentButton .leftNumber .threeRow .settlement {
    background-color: #1890ff!important;
    color: #FFFFFF;
    border-bottom-right-radius: 10px;
}

.cashier .cashierRight .cashierPaymentButton .threeRow .button {
    background-color: #FFFFFF!important;
    float: left;
    line-height: 78px;
    font-weight: bold;
    font-size: 20px;
}

.cashier .cashierRight .cashierPaymentButton .threeRow .classWhite {
    height: 80%;
    width: 20%;
}

.cashier .cashierRight .cashierPaymentButton .threeRow .classback {
    height: 80%;
    width: 40%;
}

.cashier .cashierRightList{
    width: 34%!important;
    height: 30rem;
    float: left;
    border-radius: 10px;
    margin: 0.5% 0.5% 0.5% 0%;
}

.cashier .cashierRightList .cashierMyNoColsing{
    width: 100%;
    height: 13%;
    background-color: #FFFFFF!important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.cashier .cashierRightList .cashierMyNoColsing .cashierMyNoColsingTitle{
    margin-left: 3%;
    padding-top: 3%;
    font-size: 20px;
    font-weight: bold;
}
.cashier .cashierRightList .cashierMyNoColsingList{
    width: 100%;
    height: 87%;
    background-color: #FFFFFF!important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.cashier .summary {
    width: 100%;
    height: 100%;
}

.cashier .summary .cashiertitle {
    color: rgba(0, 0, 0, 0.45);
    font-size: 17px;
    text-align: center;
    width: 100%;
    height: 20px;
    /* float: left; */
    /* margin-bottom: 15px; */
}

.cashier .summary .cashiertitleName {
    float: left;
}

.cashier .summary .cashiertitleCount {
    float: left;
    color: rgba(0, 0, 0, 0.85);
}

.cashier .summary .totalMealAmount {
    color: rgba(0, 0, 0, 0.45);
    font-size: 17px;
    width: 100%;
    /* margin-bottom: 10px; */
    /* text-align: center; */
    float: left;
    margin-top: 2rem;
}

.cashier .summary .totalMealAmount .totalMealAmountLeft .amountImg .amountImg1 {
    width: 100%;
    height: 100%;
}

.cashier .summary .totalMealAmount .totalMealAmountLeft .amountImg {
    width: 3rem;
    height: 3rem;
    text-align: center;
    margin: 6px auto;
}

.cashier .summary .totalMealAmount .totalMealAmountLeft {
    width: 15%;
    height: 100%;
    /* background-color: aqua; */
    text-align: center;
    float: left;
    /*  */
}

.cashier .summary .totalMealAmount .totalMealAmountLeft .cashiertitleName1 {
    font-size: 14px;
}

.cashier .summary .totalMealAmount .totalMealAmountLeft .cashiertitleCount1 {
    font-size: 24px;
    font-weight: 600;
}

.cashier .margintop10 {
    margin-top: 10px;
}


.cashierFrame .cashierLeft .cardNumber {
    float: left;
    width: 50%;
    margin-left: 25px;
    color: #c3c3c3;
}

.cashierFrame .cashierLeft .phone {
    float: left;
    width: 42%;
    color: #c3c3c3;
}

.cashier  .columnBtn{
    padding: 0 2px!important;
    height: 31px!important;
    width: 100%;
}

.cashier .cashierFrame .cashierLeft .head{
    height: 75px;
}
.cashier .cashierFrame .cashierLeft .head .headPortrait{
    display: inline-block;
    width: 60px;
    text-align: center;
    margin-top: 10px;
    margin-left: 50px;
}
.cashier .cashierFrame .cashierLeft .head .headPortrait img{
    height: 60px;
    width: 60px;
}
.cashier .cashierFrame .cashierLeft .head .right{
    margin-left: 10px;
    display: inline-block;
    width: 100px;
    text-align: left;
}
.cashier .cashierFrame .cashierLeft .head .right .cardType{
    color: #b8b8b8;
    text-align: left;
}
.cashier .cashierFrame .cashierLeft .head .userTitle{
    color: #2c2c2c;
}