.changePassword{
    width: 100%;
    height: 100%;
}
.changePassword .changePasswordMain {
    text-align: center;
    margin-top: 12%;
    height: 88%;
}

.changePassword .changePasswordMain .changePasswordBody{
    text-align: center;
    max-width: 300px;
    margin:0 auto!important;
}

.changePassword .changePasswordMain .changePasswordBody .item{
    height: 40px;
}

.changePassword .changePasswordMain .changePasswordBody .item .rowLeft{
    float: left;
    width: 100px;
    height: 35px;
    line-height: 35px;
    text-align: left;
}

.changePassword .changePasswordMain .changePasswordBody .item .rowRight{
    float: left;
    width: calc(100% - 100px);
}

.changePassword .changePasswordMain .changePasswordBody .item .rowRight .changePasswordInput{
    width: 100%;
    color: #000;
}
.changePassword .changePasswordMain .changePasswordBody .buttonItem{
    height: 80px;
}

.changePassword .changePasswordMain .changePasswordBody .buttonItem .changePasswordButton{
    width: 100%;
    margin: 5px 0;
}