

.promotionPerformanceDetails  .space{
    height: 10px;
    width: 10px;
}

.promotionPerformanceDetails .datePicker1 {
    width: 115px;
} 


.promotionPerformanceDetails .weight {
    font-weight: 700;
}