.loginFormRoot {
    text-align: center;
    margin:0 auto;
    padding-top:15%;
}

.loginFormInputWidth{
    max-width: 300px;
    margin:0 auto!important;
}

.loginFormButton {
    width: 100%;
}

.loginFormFrame {
    background:#5CB58C;
    color:#FFFFFF;
}

.loginFormInputWidth .loginLeftAlign{
    text-align: left;
}
.loginFormInputWidth .loginRightAlign{
    text-align: right;
}
.loginFormInputWidth .loginRegisterBut{
    text-align: left;
    float: left;
}
.bottomUrl{
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
}