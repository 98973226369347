.shopOpenGoldCard .modalTitle{
    width: 100%;
    text-align: center;
    font-size: 16px;
}

.shopOpenGoldCard .shopToPayModal .ant-modal-body{
    text-align: center;
    height: 550px;
    padding: 20px;
}

.shopOpenGoldCard .shopToPayModal .topInfo{
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    margin: 0 0 5px 0;
    color: #ccc;
}
.shopOpenGoldCard .shopToPayModal .marginBttom{
    margin: 0 0 10px 0;
}

.shopOpenGoldCard .shopToPayModal .topInfo .order{
    width: 50%;
    float: left;
    text-align: left;
}
.shopOpenGoldCard .shopToPayModal .topInfo .amount{
    width: 50%;
    float: left;
    text-align: left;
}
.shopOpenGoldCard .shopToPayModal .topInfo .amount .amountFont{
    font-size: 18px;
    color:#e31613;
}
.shopOpenGoldCard .shopToPayModal .topInfo .infoValue{
    color: rgba(0, 0, 0, 0.85);
}

.shopOpenGoldCard .shopToPayModal .textLeft{
    text-align: left;
}
.shopOpenGoldCard .shopToPayModal .textLeft .redFont{
    color: #e31613;
}

.shopOpenGoldCard .shopToPayModal .leftImg{
    width: 50%;
    height: 400px;
    float: left;
}
.shopOpenGoldCard .shopToPayModal .leftImg .payQrCode{
    width: 329px;
    height: 329px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}
.shopOpenGoldCard .shopToPayModal .leftImg .tishi{
    width: 329px;
    height: 60px;
}
.shopOpenGoldCard .shopToPayModal .rightInfo{
    width: 50%;
    height: 400px;
    float: left;
}
.shopOpenGoldCard .shopToPayModal .rightInfo .phoneBtn{
    width: 300px;
    height: 400px;
}
.shopOpenGoldCard .content .head{
    height: 75px;
}
.shopOpenGoldCard .content .head .headPortrait{
    display: inline-block;
    width: 60px;
    text-align: center;
    margin-top: 10px;
    margin-left: 50px;
}
.shopOpenGoldCard .content .head .headPortrait img{
    height: 60px;
    width: 60px;
}
.shopOpenGoldCard .content .head .right{
    margin-left: 10px;
    display: inline-block;
    width: 100px;
    text-align: left;
}
.shopOpenGoldCard .content .head .right .cardType{
    color: #b8b8b8;
    text-align: left;
}
.cashshopOpenGoldCardier .content .head .userTitle{
    color: #2c2c2c;
}