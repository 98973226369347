.cropper{
	position: fixed;
	z-index: 150;
}

.imgUploadCut .ant-upload.ant-upload-select-picture-card {
    width: 355px! important;
    height: 150px! important;
}

.imgUploadCut .ant-upload-list-picture-card .ant-upload-list-item {
    width: 355px! important;
    height: 150px! important;
}