.shopRebateManagemen .disableFontColor {
    color: rgba(0, 0, 0, 0.65)!important;
}
.shopRebateManagement .zhMiniTitle{
    height: 34px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.shopRebateManagement .disableFontColor {
    color: rgba(0, 0, 0, 0.65)!important;
}


.shopRebateManagement .RemarkInput {
    height: auto;
    min-height: 34px;
    margin-top: 5px;
    margin-right: 30px;
    width: 68.5%;
  }