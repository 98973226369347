

.marketingPromotionSettlement  .space{
    height: 10px;
    width: 10px;
}

.marketingPromotionSettlement .datePicker1 {
    width: 115px;
} 

.marketingPromotionSettlement .weight {
    font-weight: 700;
}