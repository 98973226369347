

.promotionCostSetting  .space{
    height: 10px;
    width: 10px;
}

.promotionCostSetting  .inputLeft{
    width: 50%;
    float: left;
}
.promotionCostSetting  .inputRight{
    width: 50%;
    float: left;
    text-align: center;
    line-height: 34px;
}