.rebateStatistics {
    max-width: 100vw;
    overflow: auto;
    padding: 1rem;
    background: white;
}

.rebateStatistics .zhRoot {
}

.rebateStatistics .rebateStatisticsSelect {
    width: 86px;
    margin: 0 2px;
}

.rebateStatistics .rebateStatisticsDatePicker {
    width: 120px;
}

.rebateStatistics .amountAll {
    margin-top: -50px;
    height: 100px;
    width: 50%;
}

.rebateStatistics .amount {
    font-weight: 700;
}