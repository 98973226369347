.main{
    max-height: 100vh;
    overflow: overlay;
}
.mainHeader{
    height: 65px;
    background: #337AB7;
    line-height: 65px;
    position: relative;
}
.mainHeader .systemName{
    margin: 0 1%;
    font-size: 18px;
    color: #fff;
    font-family: PingFang-SC-Light;
    font-weight: 600;
    float: left;
}
.mainHeader .menuIcon{
    font-size : 30px;
    color: #fff;
    float: left;
    margin-top: 17px;
}

.mainHeader .right{
    float: right;
    margin-right: 50px;
    color: #fff;
    font-size: 15px;
}

.mainHeader .right .li{
    float: left;
    height: 65px;
    line-height: 65px;
    margin: 0 5px;
}

.mainHeader .right .li .logoutIcon{
    font-size: 30px;
    color: #fff;
}