

.promotionPerformanceStatistics  .space{
    height: 10px;
    width: 10px;
}

.promotionPerformanceStatistics .Picker1 {
    width: 115px;
}

.promotionPerformanceStatistics .Picker {
    width: 155px;
}

.promotionPerformanceStatistics .weight {
    font-weight: 700;
}
